import React, { useState, useEffect } from 'react';
import './ShopBy.css';

import ShopByMenu from './ShopByMenu';
import ProductList from './ProductList';

import PopularProducts from './PopularProduct';
import ShopBy from '../../../../types/ShopBy';
import { getShopBy } from '../../../../api/requests/ShopByRequests';
import { getProductsByShopBy, getPopularProducts } from '../../../../api/requests/ProductRequests';
import { Product } from '../../../../types/Products';


const ShopByComponent: React.FC = () => {
  const [items, setItems] = useState<ShopBy[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);  // Utilisation de Product
  const [popularProducts, setPopularProducts] = useState<Product[]>([]);    // Utilisation de Product

  useEffect(() => {
    const fetchShopByData = async () => {
      try {
        const data = await getShopBy();
        setItems(data);

        if (data.length > 0) {
          setActiveItemId(data[0].id.toString());
        }
      } catch (error) {
        console.error("Error fetching ShopBy data", error);
      }
    };

    fetchShopByData();
  }, []);

  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const products = await getPopularProducts();
        setPopularProducts(products);  // Set directly the popular products
      } catch (error) {
        console.error("Error fetching popular products", error);
      }
    };

    fetchPopularProducts();
  }, []);

  useEffect(() => {
    const fetchProductsForShopBy = async () => {
      if (activeItemId && items.length > 0) {
        try {
          const selectedShopBy = items.find(item => item.id.toString() === activeItemId);
          if (selectedShopBy) {
            const products = await getProductsByShopBy(selectedShopBy.id.toString());
            const adaptedProducts = products.map(product => ({
              ...product,
              shopBy: [selectedShopBy.label],
              subcategory: "",
            }));
            setFilteredProducts(adaptedProducts);  // Set filtered products
          } else {
            setFilteredProducts([]);
          }
        } catch (error) {
          console.error("Error fetching products by ShopBy", error);
        }
      }
    };

    fetchProductsForShopBy();
  }, [activeItemId, items]);

  const handleClick = (id: string) => {
    setActiveItemId(id === activeItemId ? null : id);
  };

  return (
    <div className="shop-by-interface">
      <div className="shop-by-menu-container">
        <ShopByMenu items={items} activeItemId={activeItemId} onClick={handleClick} />
      </div>
      <ProductList products={filteredProducts} />
      <div>
        <PopularProducts products={popularProducts} />
      </div>
    </div>
  );
};

export default ShopByComponent;
