import React, { useState, useEffect } from 'react';
import './QuantitySelector.css';

interface QuantitySelectorProps {
  initialQuantity: number;
  onQuantityChange: (quantity: number) => void;
  alwaysOpen?: boolean;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({ initialQuantity, onQuantityChange, alwaysOpen }) => {
  const [quantity, setQuantity] = useState<number>(initialQuantity > 0 ? initialQuantity : 0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

// QuantitySelector.tsx
useEffect(() => {
  setQuantity(initialQuantity > 0 ? initialQuantity : 0);
}, [initialQuantity]); // Réinitialise la quantité à 0 si elle est supprimée

  const handleIncrement = () => {
    // Ouvre le sélecteur lorsque l'on clique et incrémente la quantité
    if (!isExpanded) {
      setIsExpanded(true);  // Ouvre le sélecteur lors du premier clic
    }
    if (quantity < 99) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleDecrement = () => {
    // Décrémentation de la quantité
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleMouseLeave = () => {
    // Ferme le sélecteur si le curseur quitte la zone
    if (!alwaysOpen && isExpanded) {
      setIsExpanded(false);
    }
  };

  return (
    <div
      className="quantity-selector"
      onMouseLeave={handleMouseLeave}  // Ferme lorsque le curseur quitte la zone
    >
      {isExpanded ? (
        <div className="quantity-box">
          <button
            className="icon-button decrement-button"
            onClick={handleDecrement}
            aria-label="Decrease quantity"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className="icon">
              <path stroke="currentColor" strokeWidth="1.5" d="M0 9h18" />
            </svg>
          </button>

          <span className="quantity-display">{quantity}</span>

          <button
            className="icon-button increment-button"
            onClick={handleIncrement}
            aria-label="Increase quantity"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className="icon">
              <path stroke="currentColor" strokeWidth="1.5" d="M0 9h18M9 0v18" />
            </svg>
          </button>
        </div>
      ) : (
        <button
          className={`icon-button toggle-button ${quantity > 0 ? 'quantity-greater-than-1' : ''}`}
          onClick={handleIncrement}  // Ouvre le sélecteur au clic
          aria-label="Open quantity selector"
        >
          {quantity > 0 ? (
            <span className="quantity-display quantity-indicator">{quantity}</span>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className="icon">
              <path stroke="currentColor" strokeWidth="1.5" d="M0 9h18M9 0v18" />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default QuantitySelector;
