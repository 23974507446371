import axios from "axios";



export const baseUrl = 'https://api.bingsepicerie.com/api/';



const HTTP_CLIENT = axios.create({
    timeout: 60000, // 1 minute
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});


/*HTTP_CLIENT.interceptors.request.use(
   async config => {
        const token  =  localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


HTTP_CLIENT.interceptors.response.use(
    response => {
        return response;
    },
     error => {
        if (error.response.status === 401) {

        localStorage.removeItem('token');

        window.location.href = ROUTES.LOGIN;
        }
        return Promise.reject(error);
    }
);
*/






export default HTTP_CLIENT;

