import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './DropdownMenu.css';
import { MenuData } from '../../types/MenuData';
import { Category } from '../../types/Category';
import { getCategoriesByMenuData } from '../../api/requests/categorieRequests';
import { getMenuDataByNavItemId } from '../../api/requests/menuDataRequests';
import { getNavItemById } from '../../api/requests/navItemRequests';

interface DropdownProps {
  navItemId: string;
  closeDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ navItemId, closeDropdown }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const location = useLocation();
  const [navItemLabel, setNavItemLabel] = useState('');

  const fetchNavItemLabel = async () => {
    try {
      // Remplacez par l'API réelle qui retourne le label pour un navItemId donné
      const data = await getNavItemById(navItemId);
      setNavItemLabel(data.label); // Supposons que l'API retourne un objet avec un champ `label`
    } catch (error) {
      console.error("Erreur lors de la récupération du label de NavItem:", error);
    }
  };

  const fetchMenuData = async () => {
    if (!navItemId) return;
    try {
      const data = await getMenuDataByNavItemId(navItemId);
      setMenuData(data);
      if (data.length > 0) {
        fetchCategoriesForMenuData(data[0].id);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des MenuData:", error);
    }
  };



  const fetchCategoriesForMenuData = async (menuId: string) => {
    try {
      const data = await getCategoriesByMenuData(menuId);
      setCategories(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  };

  useEffect(() => {
    if (navItemId) {
      fetchNavItemLabel();
      fetchMenuData();
    }
  }, [navItemId]);


  const handleMenuClick = (index: number, menuId: string) => {
    setActiveTab(index);
    fetchCategoriesForMenuData(menuId);
  };
  const generateUrl = (categoryLabel: string) => {
    if (!navItemLabel || !menuData[activeTab]?.label || !categoryLabel) {
      console.error("Labels nécessaires manquants pour générer l'URL");
      return '#';
    }

    const formattedNavItemLabel = formatLabelForUrl(navItemLabel);
    const formattedMenuLabel = formatLabelForUrl(menuData[activeTab].label);
    const formattedCategoryLabel = formatLabelForUrl(categoryLabel);

    return `/category/${formattedNavItemLabel}/${formattedMenuLabel}/${formattedCategoryLabel}`;
  };




  const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/&/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleLinkClick = () => {
    closeDropdown();
  };

  // Divisez les catégories en groupes de 4
  const chunkCategories = (categories: Category[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < categories.length; i += chunkSize) {
      result.push(categories.slice(i, i + chunkSize));
    }
    return result;
  };

  // Divisez les catégories en groupes de 4
  const categoriesGrouped = chunkCategories(categories, 4);

  return (
    <div className="dropdown">
      <div className="tabs">
        {menuData.map((tab, index) => (
          <button
            key={tab.label}
            className={`tab-button ${activeTab === index ? 'active-tab' : ''}`}
            onClick={() => handleMenuClick(index, tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <hr className="hr-separator" />

      <div className="categories-grid">
        {categoriesGrouped.map((categoryGroup, groupIndex) => (
          <div key={groupIndex} className="category-column">
            {categoryGroup.map((category) => (
              <NavLink
                key={category.id}
                to={navItemLabel ? generateUrl(category.label) : '#'} // Ne génère pas l'URL si le label n'est pas prêt
                className={`category-link ${location.pathname.includes(category.label) ? 'active' : ''}`}
                onClick={handleLinkClick}
              >
                {category.label}
              </NavLink>
            ))}
          </div>
        ))}
      </div>

      <div className="see-all">
        <NavLink
          to={
            navItemLabel && menuData[activeTab]?.label
              ? `/${formatLabelForUrl(navItemLabel)}/${formatLabelForUrl(menuData[activeTab].label)}`
              : '#'
          }
          className="see-all-link"
          onClick={handleLinkClick}
        >
          Voir tout {menuData[activeTab]?.label}
        </NavLink>

      </div>


    </div>
  );
};

export default Dropdown;
