import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import des icônes de flèche
import './PopularProduct.css';

import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { Link } from 'react-router-dom';
import { getPopularProducts, getShopByForProduct } from '../../../../api/requests/ProductRequests';

import { Product } from '../../../../types/Products';
import ShopBy from '../../../../types/ShopBy';
import { baseUrl } from '../../../../api/client';

interface PopularProductsProps {
    products: Product[];
}

const PopularProducts: React.FC<PopularProductsProps> = ({ products }) => {
    const [popularProducts, setPopularProducts] = useState<Product[]>([]);
    const [shopByLabels, setShopByLabels] = useState<{ [key: string]: { label: string; color: string }[] }>({});
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const { cartItems, updateQuantity, addToCart, removeFromCart } = useCart();

    const [currentIndex, setCurrentIndex] = useState(0);
    const productsPerRow = 3;
    const totalProducts = popularProducts.length;

    useEffect(() => {
        const fetchShopByForProduct = async (productId: string) => {
            try {
                const productShopBy = await getShopByForProduct(productId); // Récupérer les labels ShopBy pour un produit
    
                setShopByLabels((prev) => ({
                    ...prev,
                    [productId]: productShopBy.length > 0
                        ? productShopBy.map((shopBy) => ({
                            label: shopBy.label,
                            color: shopBy.color || 'black', // Couleur par défaut si aucune n'est fournie
                        }))
                        : [{ label: 'Aucun label disponible', color: 'gray' }],
                }));
            } catch (err) {
                console.error('Erreur lors du chargement des ShopBy pour le produit:', err);
            }
        };
    
        const fetchData = async () => {
            try {
                const response = await getPopularProducts(); // Récupérer les produits populaires depuis l'API
                const products = response;  // Extraire les produits depuis la réponse 'data'
                
                setPopularProducts(products);
    
                // Récupérer les ShopBy pour chaque produit
                products.forEach((product) => {
                    fetchShopByForProduct(product.id);
                });
            } catch (error) {
                console.error("Erreur lors de la récupération des produits ou ShopBy:", error);
            }
        };
    
        fetchData();
    }, []); // Tableau de dépendances vide, pour que cela s'exécute une seule fois au montage du composant
    

    const displayedProducts = popularProducts.slice(currentIndex, currentIndex + productsPerRow);

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - productsPerRow);
        }
    };

    const handleNext = () => {
        if (currentIndex + productsPerRow < totalProducts) {
            setCurrentIndex(currentIndex + productsPerRow);
        }
    };

    const handleAddToCart = (product: Product, quantity: number) => {
        let mediaUrl = '';
        // If media is a file
        if (product.media instanceof File) {
            mediaUrl = URL.createObjectURL(product.media); // Create URL for the file
        } else {
            mediaUrl = product.media; // Use existing URL if it's not a file
        }

        const cartItem = {
            id: product.id.toString(),
            name: product.name,
            quantity,
            price: parseFloat(product.price.toString()),
            media: mediaUrl,
            brand: product.brand,
            unitSize: product.unitSize,
            unitPrice: product.unitPrice,
            shopByIds: product.shopByIds,
        };

        addToCart(cartItem, quantity);
    };

    const handleQuantityChange = (productName: string, quantity: number) => {
        setQuantities((prev) => ({ ...prev, [productName]: quantity }));

        const product = popularProducts.find((p) => p.name === productName);
        if (product) {
            if (quantity > 0) {
                const cartItemExists = quantities[productName] && quantities[productName] > 0;

                if (!cartItemExists) {
                    handleAddToCart(product, quantity);
                }

                updateQuantity(productName, quantity);
            } else {
                removeFromCart(productName);
            }
        }
    };

    useEffect(() => {
        const updatedQuantities: { [key: string]: number } = {};
        cartItems.forEach(item => {
            updatedQuantities[item.name] = item.quantity;
        });
        setQuantities(updatedQuantities);
    }, [cartItems]);

    return (
        <div className="popular-products-container border-top">
            <div className="content-section">
                <div className="text-column">
                    <h2>Popular Products</h2>
                    <Link to="/all-products" className="view-button mobile-only">View All</Link>
                    <div className="navigation-and-description">
                        <p>Discover our selection of popular products. Browse through them and find what you love!</p>
                        {totalProducts > 0 && (
                            <div className="product-navigation">
                                <button className="prev-button" onClick={handlePrev} disabled={currentIndex === 0}>
                                    <FaChevronLeft />
                                </button>
                                <button
                                    className="next-button"
                                    onClick={handleNext}
                                    disabled={currentIndex + productsPerRow >= totalProducts}
                                >
                                    <FaChevronRight />
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="product-list-column">
                        <div className="product-list1">
                            {displayedProducts.map((product, index) => (
                                <div className={`product-item ${index !== displayedProducts.length - 1 ? 'border-right' : ''}`} key={product.id}>
                                    <div className="flex justify-center mb-4">
                                        {product.media instanceof File ? (
                                            product.media.type.startsWith('video') ? (
                                                <video src={`${baseUrl}${URL.createObjectURL(product.media)}`} className="product-image" controls />
                                            ) : (
                                                <img src={`${baseUrl}${URL.createObjectURL(product.media)}`} alt={product.name} className="product-image" />
                                            )
                                        ) : (
                                            <img src={`${baseUrl}${product.media}`} alt={product.name} className="product-image" />
                                        )}
                                    </div>

                                    <div className="shop-by-labels">
                                        {shopByLabels[product.id] && shopByLabels[product.id].length > 0 ? (
                                            shopByLabels[product.id].map((shopBy, i) => (
                                                <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                                                    {shopBy.label}
                                                    {i !== shopByLabels[product.id].length - 1 && <span className="vertical-divider"> | </span>}
                                                </span>
                                            ))
                                        ) : (
                                            <span>Aucun label disponible</span>
                                        )}
                                    </div>

                                    <div>
                                        <p className="product-brand">{product.brand}</p>
                                        <p className="product-name">{product.name}</p>
                                    </div>

                                    <div>
                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className="product-unit-size">{product.unitSize}</span>
                                            <div className="vertical-line"></div>
                                            <span className="original-price">{product.unitPrice}</span>
                                        </p>
                                    </div>

                                    <div className="product-card-container">
                                        <p>
                                            <strong className="current-price">{product.price}</strong>
                                        </p>
                                        <div className="right-container">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" style={{ marginRight: '8px' }}>
                                                <path stroke="#666" strokeLinecap="round" strokeLinejoin="round" d="M3.5 3a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v11L8 9.5 3.5 14z"></path>
                                            </svg>
                                            <QuantitySelector
                                                initialQuantity={quantities[product.name] || 0}
                                                onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-column">
                <img src="assets/menu/2 1.PNG" alt="Image des produits populaires" className="product-Image" />
            </div>
        </div>
    );
};

export default PopularProducts;
