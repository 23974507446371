import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { getShopBy } from "../../../../api/requests/ShopByRequests";
import ShopBy from "../../../../types/ShopBy";



interface ShopByMenuProps {
  items: ShopBy[];
  activeItemId: string | null; // Mise à jour ici
  onClick: (id: string) => void; // Mise à jour ici
}
const ShopByMenu: React.FC<ShopByMenuProps> = ({ activeItemId, onClick }) => {
  const [items, setItems] = useState<ShopBy[]>([]);
  const [isVisible, setIsVisible] = useState(true);
  const [userToggled, setUserToggled] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  // Déterminer si on est sur la page d'accueil
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const fetchShopByData = async () => {
      try {
        const data = await getShopBy();
        setItems(data);
        setLoading(false);
      } catch (error) {
        setError("Erreur lors de la récupération des données");
        setLoading(false);
      }
    };

    fetchShopByData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 500; // Valeur en pixels à partir de laquelle cacher les éléments
      if (!userToggled) {
        setIsVisible(window.scrollY <= scrollThreshold);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [userToggled]);

  const toggleVisibility = () => {
    setUserToggled(true);
    setIsVisible((prev) => !prev);
  };

  if (!isHomePage) {
    return null; // Ne rien afficher si ce n'est pas la page d'accueil
  }

  return (
    <div className="shop-by-menu" style={{ display: "flex", alignItems: "center" }}>
      <ul className="shop-by-items">
        {isVisible && (
          <span className="shop-by-label" >
            Shop by
          </span>
        )}
        {loading && <div>Loading...</div>}
        {error && <div>{error}</div>}
        {isVisible &&
          items.map((item) => (
            <li
              key={item.id}
              className={`shop-by-item ${activeItemId === item.id ? "active" : ""}`}
              onClick={() => onClick(item.id)}
              style={{
                backgroundColor: activeItemId === item.id ? item.color : "transparent",
                display: "inline-block",
                marginRight: "10px",
                padding: "10px 15px",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: activeItemId === item.id ? "#ffffff" : item.color,
                }}
              >
                {item.label}
              </span>
            </li>
          ))}
        {!isVisible && (
          <button onClick={toggleVisibility} className="toggle-shopby-btn">
            Shop by
            <FaChevronRight
              style={{ marginLeft: "10px", transform: isVisible ? "rotate(90deg)" : "rotate(0deg)" }}
            />
          </button>
        )}
      </ul>
    </div >
  );
};

export default ShopByMenu;
