import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DepartmentsSection from '../Components/DepartmentsSection/DepartmentsSection';

import ShopByMenu from '../Components/HomePage/MainContent/ShopBy/ShopByMenu';
import { ShopByData } from '../Components/HomePage/MainContent/ShopBy/ShopByData';
import ShopBy from '../types/ShopBy';
import { getNavItemsByType } from '../api/requests/navItemRequests';

const DepartmentPage: React.FC = () => {
  const { navItemLabel } = useParams<{ navItemLabel: string }>(); // Récupérer le label du navItem
  const [navItems, setNavItems] = useState<any[]>([]);
  const [items, setItems] = useState<ShopBy[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const items = await getNavItemsByType("HOME"); // Remplacez "HOME" par le type approprié si nécessaire
        setNavItems(items);
        console.log("NavItems fetched:", items); // Vérifier les NavItems récupérés
      } catch (error) {
        console.error("Erreur lors de la récupération des NavItems:", error);
      }
    };

    fetchNavItems();
  }, []);

  // Rechercher l'ID correspondant au label récupéré
  useEffect(() => {
    if (navItemLabel) {
      console.log("Searching for NavItem with label:", navItemLabel); // Vérifier le label récupéré

      const foundNavItem = navItems.find((item) => {
        const labelSlug = item.label.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-');
        console.log("Checking label slug:", labelSlug); // Vérifier le slug généré
        return labelSlug === navItemLabel;
      });

      if (foundNavItem) {
        console.log("Found NavItem:", foundNavItem); // Vérifier le NavItem trouvé
        setActiveItemId(foundNavItem.id);
      } else {
        console.log("NavItem not found for label:", navItemLabel); // Vérifier si le NavItem est trouvé ou non
      }
    }
  }, [navItemLabel, navItems]);

  const handleClick = (id: string) => {
    setActiveItemId(id);
  };

  return (
    <div className="department-page">
      <ShopByMenu
        items={items}
        activeItemId={activeItemId}
        onClick={handleClick}
      />
      {navItemLabel && activeItemId && <DepartmentsSection navItemId={activeItemId} />}
    </div>
  );
};

export default DepartmentPage;
