import NavItem from "../../types/NavItem";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "nav_items";

// Récupérer tous les NavItems
export const getNavItems = async (): Promise<NavItem[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: NavItem[] }>(API_ENDPOINT);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des NavItems:", error);
    throw error;
  }
};

// Récupérer un NavItem par son ID
export const getNavItemById = async (id: string): Promise<NavItem> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: NavItem }>(`${API_ENDPOINT}/${id}`);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération du NavItem:", error);
    throw error;
  }
};

// Récupérer les NavItems par type
export const getNavItemsByType = async (type: string): Promise<NavItem[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: NavItem[] }>(`${API_ENDPOINT}/type/${type}`);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des NavItems par type:", error);
    throw error;
  }
};

// Créer un nouveau NavItem
export const createNavItem = async (formData: FormData): Promise<NavItem> => {
  try {
    const response = await HTTP_CLIENT.post<{ data: NavItem }>(API_ENDPOINT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la création du NavItem:", error);
    throw error;
  }
};

// Mettre à jour un NavItem existant
export const updateNavItem = async (id: string, formData: FormData): Promise<NavItem> => {
  try {
    const response = await HTTP_CLIENT.put<{ data: NavItem }>(`${API_ENDPOINT}/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la mise à jour du NavItem:", error);
    throw error;
  }
};

// Supprimer un NavItem
export const deleteNavItem = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression du NavItem:", error);
    throw error;
  }
};


export const getNavItemIdByLabel = async (label: string): Promise<string> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: string; message: string; success: boolean }>(
      `${API_ENDPOINT}/label/${label}`
    );

    if (response.data.success) {
      return response.data.data; // Retourne l'ID du NavItem (UUID)
    } else {
      throw new Error(response.data.message); // Si le succès est faux, on lance une erreur avec le message
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'ID du NavItem par label:", error);
    throw error;
  }
};
