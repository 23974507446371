// SubcategoryList.tsx

import React from 'react';
import './SubCategoriesList.css'; // Ajoutez des styles si nécessaire

interface SubcategoryListProps {
  categoryLabel: string;  // Le label de la catégorie
  subcategories: string[];  // Liste des sous-catégories passées comme prop
  onSubcategoryClick: (subcategory: string) => void; // Callback pour gérer le clic
}

const SubcategoryList: React.FC<SubcategoryListProps> = ({ categoryLabel, subcategories, onSubcategoryClick }) => {
  return (
    <div className="subcategories-container">
      <ul>
        {subcategories.length > 0 ? (
          subcategories.map((subcategory, index) => (
            <li key={index}>
              <button
                onClick={() => onSubcategoryClick(subcategory)}
                className="subcategory-link"
              >
                {subcategory}
              </button>
            </li>
          ))
        ) : (
          <p>Aucune sous-catégorie disponible</p>
        )}
      </ul>
    </div>
  );
};

export default SubcategoryList;
