import React from 'react';
import './Breadcrumbs.css';

interface BreadcrumbItem {
  label: string; // Texte à afficher
  path?: string; // Lien (facultatif, si absent, c'est un élément actif)
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[]; // Liste des éléments du fil d'Ariane
  totalProducts?: number; // Compteur facultatif à afficher avec le dernier élément
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, totalProducts }) => {
  return (
    <div className="bread">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}
            >
              {item.path && index !== breadcrumbs.length - 1 ? (
                <a href={item.path}>{item.label}</a> // Si l'élément n'est pas actif, il est cliquable
              ) : (
                <>
                  {item.label}
                  {index === breadcrumbs.length - 1 && totalProducts !== undefined && (
                    <span className="Breadcrumbs_counter"> ({totalProducts})</span> // Affiche le compteur pour l'élément actif
                  )}
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
