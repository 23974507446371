import React from 'react';

interface MenuIconProps {
  isMenuOpen: boolean;
  onToggle: () => void;
}

const MenuIcon: React.FC<MenuIconProps> = ({ isMenuOpen, onToggle }) => {
  return (
    
    <button className="mr-4" onClick={onToggle} aria-label="Toggle menu">
      {isMenuOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          color="none"
          viewBox="0 0 21 11"
          width="20"
          height="10"
        >
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M.7 9.75h20M.7 1.717h20"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          color="none"
          viewBox="0 0 21 11"
          width="20"
          height="10"
        >
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M.7 9.75h20M.7 1.717h20"
          />
        </svg>
      )}
    </button>
  );
};

export default MenuIcon;
