import React, { useState, useEffect } from 'react';
import { FooterData } from './FooterData';
import './Footer.css';

const Footer: React.FC = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [items, setItems] = useState(FooterData);

  const handleScroll = () => {
    const position = window.scrollY;
    setShowBackToTop(position > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="footer-container">
      <button
        className={`back-to-top ${showBackToTop ? 'show' : 'hide'}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        aria-label="Back to Top"
      >
        Back to Top
      </button>
      <div className="footer-content">
        {items.map((column, index) => (
          <div key={index} className="footer-column">
            <p className="footer-title">{column.title}</p>
            <ul className="footer-links">
              {column.links.map((link, idx) => (
                <li key={idx} className="footer-link-item">
                  <a href={link.href} onClick={(e) => e.preventDefault()}>
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
            {column.subSection && (
              <>
                <p className="footer-subtitle">{column.subSection.title}</p>
                <ul className="footer-links">
                  {column.subSection.links.map((link, idx) => (
                    <li key={idx} className="footer-link-item">
                      <a href={link.href} onClick={(e) => e.preventDefault()}>
                        {link.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ))}
      </div>
      <hr className="footer-divider" />
      <div className="footer-bottom">
        <span>&copy; 2024 Bings Epicerie, SARL</span>
        <span className="footer-bottom-text">All Rights Reserved.</span>
        <div className="footer-bottom-links">
          <a href="/help/privacy_policy.jsp">Privacy Policy</a>
          <a href="/help/terms_of_service.jsp">Customer Agreement</a>
          <a href="/help/platform_agreement.jsp">Terms of Use</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
