export const carouselData = [
    {
      id: 1,
      media: '/assets/videos/vegetables/1.mp4',  
    },
    {
      id: 2,
      media: '/assets/videos/vegetables/2.mp4',  
    },
    {
      id: 3,
      media: '/assets/videos/vegetables/3.mp4',  
    },
   
  ];
  