// src/data.ts
export const itemsToSearch: string[] = [
    'Services',
    'Business Solutions',
    'About Us',
    'Contact Us',
    'Salad',
    'Pizza'
    // Ajoutez d'autres éléments ici
  ];
  