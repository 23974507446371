import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCategoriesByMenuData } from '../api/requests/categorieRequests';
import { getMenuDataByNavItemId } from '../api/requests/menuDataRequests';
import { getNavItemIdByLabel } from '../api/requests/navItemRequests';
import { Category } from '../types/Category';
import './DepartmentsPage2.css';

interface DepartmentsPage2Props {
  navItemLabel: string;
  menuDataLabel: string;
}

const DepartmentsPage2: React.FC = () => {
  const { navItemLabel, menuDataLabel } = useParams<{ navItemLabel: string; menuDataLabel: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [menuData, setMenuData] = useState<any>(null);
  const [navItemId, setNavItemId] = useState<string | null>(null); // Declare state for navItemId
  const [error, setError] = useState<string>('');

  const restoreOriginalLabel = (label: string) => {
    return label
      .replace(/-/g, '&')    // Remplacer les tirets par le caractère `&` (qui était remplacé dans le formatage)
      .replace(/_/g, ' ')    // Remplacer les underscores par des espaces (qui étaient des espaces avant formatage)
      .replace(/^-|-$/g, '') // Supprimer les tirets en début et fin de chaîne (si présents)
      
  };


  const restoreOriginalLabelbread = (label: string) => {
    return label
      .replace(/-/g, '&')    // Remplacer les tirets par le caractère `&` (qui était remplacé dans le formatage)
      .replace(/_/g, ' ')    // Remplacer les underscores par des espaces (qui étaient des espaces avant formatage)
      .replace(/^-|-$/g, '') // Supprimer les tirets en début et fin de chaîne (si présents)
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Mettre en majuscule la première lettre de chaque mot
  };
  const fetchNavItemId = async () => {
    if (navItemLabel) {
      try {
        const originelabel = restoreOriginalLabel(navItemLabel);
        const navItemId = await getNavItemIdByLabel(originelabel);
        if (!navItemId) {
          throw new Error('ID du NavItem introuvable');
        }
        setNavItemId(navItemId);
        console.log('NavItem ID fetched:', navItemId);
      } catch (err) {
        console.error("Erreur lors de la récupération de l'ID du NavItem:", err);
        setError('Erreur lors de la récupération de l\'ID du NavItem');
      }
    }
  };

  const fetchMenuData = async () => {
    if (navItemId) {
      try {
        const data = await getMenuDataByNavItemId(navItemId);
        setMenuData(data); // Assurez-vous que 'data' est un tableau
        if (data && data.length > 0 && data[0]?.id) { // Vérifiez si l'élément a un 'id'
          fetchCategoriesForMenuData(data[0].id); // Utilisez data[0].id si vous travaillez avec un tableau
        }
      } catch (error) {
        console.error("Error fetching MenuData:", error);
      }
    }
  };

  const fetchCategoriesForMenuData = async (menuId: string) => {
    try {
      const data = await getCategoriesByMenuData(menuId);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (navItemLabel && menuDataLabel) {
      fetchNavItemId();
    }
  }, [navItemLabel, menuDataLabel]); // Dependency array: fetch when navItemLabel or menuDataLabel changes

  useEffect(() => {
    if (navItemId) {
      fetchMenuData();
    }
  }, [navItemId]); // Dependency array: fetch when navItemId is set

  const generateUrl = (categoryLabel: string) => {
    // Génère une URL avec navItemLabel, menuDataLabel, et categoryLabel
    return `/category/${navItemLabel}/${menuDataLabel}/${categoryLabel.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')}`;
  };

  const generateColumns = (categories: Category[]) => {
    const maxItemsPerColumn = 4;
    const columns = [];

    for (let i = 0; i < categories.length; i += maxItemsPerColumn) {
      columns.push(categories.slice(i, i + maxItemsPerColumn));
    }

    return columns;
  };

  return (
    <div className="category-page">
      <nav aria-label="breadcrumb" className="breadcrumbs">
        <ol className="bread">
        <li className="breadcrumb-item">
  <Link to={`/department/${navItemLabel ? restoreOriginalLabel(navItemLabel).replace(/ /g, '-').replace(/&/g, '-') : ''}`}>
    {navItemLabel ? restoreOriginalLabelbread(navItemLabel) : ''}
  </Link> {/* Décoder et re-coder le label ici */}
</li>


          <li className="breadcrumb-item active" aria-current="page">
            <span>{menuDataLabel}</span> {/* Affiche le label du premier élément */}
          </li>
        </ol>
      </nav>

      <div className="categories-grid">
        {generateColumns(categories).map((column, columnIndex) => (
          <div key={columnIndex} className="category-column">
            {column.map((category) => (
              <Link
                key={category.id}
                to={generateUrl(category.label)}
                className="category-link"
              >
                {category.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepartmentsPage2;
