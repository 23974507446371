import React from 'react';
import ShopByComponent from '../Components/HomePage/MainContent/ShopBy/ShopBy';
import { Product } from '../types/Products';



const HomePage: React.FC = () => {
  const products: Product[] = [/* vos produits ici */];

  return (
    <main>
      
      <ShopByComponent  />
    </main>
  );
};

export default HomePage;
