import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import des styles de base
import { Autoplay } from 'swiper/modules';
import { carouselData } from './CarouselData'; // Import des données de carousel
import './PromoSlider.css';

// Interface pour les données de promotion
interface Promo {
    id: number;
    media: string;
}

const Carousel: React.FC = () => {
    const [promotions, setPromotions] = useState<Promo[]>([]);

    useEffect(() => {
        setPromotions(carouselData);
    }, []);

    const isVideo = (media: string) => {
        return media.endsWith('.mp4') || media.endsWith('.webm') || media.endsWith('.ogg');
    };

    return (
        <div className="promotions-2">
            <Swiper
                modules={[Autoplay]}
                
                slidesPerView={1}
                loop
                autoplay={{ delay: 9000 }}
            >
                {promotions.map((promo) => (
                    <SwiperSlide key={promo.id}>
                        <div className="promo-item-2">
                            {isVideo(promo.media) ? (
                                <video
                                    className="promo-media-2 img-fluid"
                                    autoPlay
                                    muted
                                    loop
                                >
                                    <source src={promo.media} type="video/mp4" />
                                </video>
                            ) : (
                                <img
                                    alt={`Promo ${promo.id}`}
                                    className="promo-media-2 img-fluid"
                                    src={promo.media}
                                />
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Carousel;
