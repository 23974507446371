import React, { useEffect, useState } from 'react';
import { GiveawaysData } from './GiveawayData';
import './Giveaway.css';
interface Giveaway {
  title: string;
}

const GiveawayComponent: React.FC = () => {
  const [giveaways, setGiveaways] = useState<Giveaway[]>([]);
  const [claimed, setClaimed] = useState<string | null>(null);


  // Fonction pour générer le lien en fonction du titre du giveaway
  const generateLink = (title: string): string => {
    // Remplacer les espaces par des tirets et rendre le titre en minuscules pour créer un lien SEO-friendly
    return `https://www.bingsepicerie.com/${title.toLowerCase().replace(/\s+/g, '-')}`;
  };

  useEffect(() => {
    // Initialiser l'état avec les données statiques
    setGiveaways(GiveawaysData);
  }, []); 

  const handleClaim = (title: string) => {
    setClaimed(title);
  };

  return (
    <div>
      {giveaways.map((giveaway) => (
        <div key={giveaway.title} className="giveaway">
          <span className="giveaway-title">
            {giveaway.title}
            {/* Si le giveaway n'a pas encore été réclamé, afficher le bouton */}
            {claimed !== giveaway.title ? (
              <a
                href={generateLink(giveaway.title)} // Utiliser la fonction pour générer le lien
                target="_blank"
                rel="noopener noreferrer"
                className="giveaway-link"
                onClick={() => handleClaim(giveaway.title)}
              >
                <strong> réclamez le vôtre &gt;&gt;</strong>
              </a>
            ) : (
              // Si le giveaway a été réclamé, afficher "Already claimed!"
              <span className="claimed-message"> Already claimed!</span>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default GiveawayComponent;
