import React, { useState } from 'react';
import './CreateAccount.css';

// Définition des types pour les props
interface CreateAccountProps {
    isOpen: boolean;
    onClose: () => void;
}
interface DeliveryOption {
    id: number;
    label: string;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ isOpen, onClose }) => {
    const [signInType, setSignInType] = useState<'EMAIL' | 'PHONE'>('EMAIL');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>(''); // Type pour le numéro de téléphone
    const [isPersistent, setIsPersistent] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>(''); // État pour le nom de l'entreprise ou de l'école
    const [firstName, setFirstName] = useState<string>(''); // État pour le prénom
    const [lastName, setLastName] = useState<string>(''); // État pour le nom de famille
    const [zipCode, setZipCode] = useState<string>(''); // État pour le code postal

    const deliveryOptions: DeliveryOption[] = [
        { id: 1, label: 'Home' },
        { id: 2, label: 'Business or school' },
    ];

    const [selectedOption, setSelectedOption] = useState<number | null>(1);

    const handleOptionChange = (id: number) => {
        setSelectedOption(id);
        // Réinitialiser les champs supplémentaires si l'option "Home" est sélectionnée
        if (id === 1) {
            setCompanyName('');
            setFirstName('');
            setLastName('');
            setZipCode('');
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSignInTypeChange = (type: 'EMAIL' | 'PHONE') => {
        setSignInType(type);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value); // Mettre à jour le numéro de téléphone
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value);
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipCode(event.target.value);
    };

    const handlePersistentChange = () => {
        setIsPersistent(!isPersistent);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(`Sign in as: ${signInType}, Email: ${email}, Stay signed in: ${isPersistent}`);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div data-testid="logo" className="TopLine_logo_wrapper" role="group" tabIndex={0}>
                        <a className="TopLine_logo" href="#">
                            <img src="/assets/SVG/logo.SVG" alt="Freshdirect logo" className="TopLine_logo_image" />
                        </a>
                    </div>
                    <div className="close-icon" role="button" tabIndex={0} onClick={onClose}>

                        <img src="/assets/SVG/close.png" alt="Close icon" className="close-icon-image" />

                    </div>
                </div>
                <h2 id="auth_modal_title">Create an account</h2>
                <div className="delivery-options-container">
                    <p className="delivery-options-title">I want grocery delivery for my:</p>
                    <div className="delivery-options-wrapper">
                        <div className="delivery-options-list">
                            {deliveryOptions.map((option) => (
                                <div key={option.id} className="delivery-option-item">
                                    <input
                                        type="radio"
                                        id={`option-${option.id}`}
                                        checked={selectedOption === option.id}
                                        onChange={() => handleOptionChange(option.id)}
                                        className="delivery-option-input"
                                    />
                                    <label htmlFor={`option-${option.id}`} className="delivery-option-label">
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <section className="AuthV2_container">
                    <div className="Toggle_container">
                        <div className="Toggle_option">
                            <label
                                htmlFor="sign-up-type_PHONE"
                                className={`Toggle_label ${signInType === 'PHONE' ? 'active' : ''}`}
                                onClick={() => handleSignInTypeChange('PHONE')}
                            >
                                <span className="Toggle_icon">
                                    <img src="/assets/SVG/phone.png" alt="Phone" width={16} height={16} />
                                </span>
                                Phone Number
                            </label>
                        </div>
                        <div className="Toggle_option">
                            <label
                                htmlFor="sign-up-type_EMAIL"
                                className={`Toggle_label ${signInType === 'EMAIL' ? 'active' : ''}`}
                                onClick={() => handleSignInTypeChange('EMAIL')}
                            >
                                <span className="Toggle_icon">
                                    <img src="/assets/SVG/gmail.png" alt="Email" width={16} height={16} />
                                </span>
                                Email
                            </label>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {signInType === 'EMAIL' && (
                            <div className="SignInEmail_field">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder=" " /* Ajoutez un placeholder vide pour l'effet flottant */
                                    required
                                />
                                <label htmlFor="email" className={signInType === 'EMAIL' ? 'active' : ''}>
                                    Email
                                </label>
                                <p className="text-sm text-gray-500 mb-2">We will text a 6-digit sign-in code to this phone number</p>
                            </div>
                        )}
                        {signInType === 'PHONE' && (
                            <div className="SignInPhone_field">
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder=" " /* Ajoutez un placeholder vide pour l'effet flottant */
                                    required
                                />
                                <label htmlFor="phone" className={signInType === 'PHONE' ? 'active' : ''}>
                                    Phone Number
                                </label>
                                <p className="text-sm text-gray-500 mb-2">We will text a 6-digit sign-in code to this phone number</p>
                            </div>
                        )}
                        {/* Champs supplémentaires pour "Business or school" */}
                        {selectedOption === 2 && (
                            <>
                                <div className='SignInEmail_field'>
                                    <label htmlFor="companyName">Business or School Name</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        value={companyName}
                                        onChange={handleCompanyNameChange}
                                        required
                                    />
                                </div>
                                <div className="form-container">
                                    <div className='form'>
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                            required
                                        />
                                    </div>
                                    <div className='form'>
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                            required
                                        />
                                    </div>
                                    <div className='form'>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            id="email"
                                            value={email} 
                                            onChange={handleZipCodeChange}
                                            required
                                        />
                                    </div>
                                    <div className='form'>
                                        <label htmlFor="zipCode">Zip Code</label>
                                        <input
                                            type="text"
                                            id="zipCode"
                                            value={zipCode}
                                            onChange={handleZipCodeChange}
                                            required
                                        />
                                    </div>
                                </div>



                            </>
                        )}
                        <div className="SignInEmail_submit_button_wrapper">
                            <button type="submit" disabled={!email && !phoneNumber}>Continue</button>
                        </div>
                        <div className="SocialButtons_socials_modal">
                            <div className="custom-divider" role="separator">
                                <span>or</span>
                            </div>
                            <div>
                                <iframe
                                    title="Login with Social Networks"
                                    src="https://freshdirect.api.oneall.com/socialize/login/frame/?oakk=25656&oakv=0655ee9235e519cdf7de6cbf1b20ab1fb10e5e240f87805fe927cdfa03de7961abddb9fdd240c58391ba3191da35e7ef35cd022b1584cbbf4562939559b254fa1a8cfe45c189164da58cd7ab67377f6662c40588dce40708d505c5af6084e78284716bb0816913d2b44f429f2cde0c46c6d26d9451baa70dddc25f096ccabc302e72ccd790bb680fe3e36f8570737e78897f06ad8d7a4220d85877cac80be8ecba6cc3f06e19f141188c761647df653b046d892bffc6602783a33d996fc963122be71bc41d64ec87e19d27d177ed05d80f6812007b8576c8a323c35be70ea23d1bb9ca11a31a3dadab9f2895907c7175e9d3228b1005249b1bca5721ff54f41d21a8aba21d2ed17aefeedc345ad5f25d92b4613e4a1bc31fcc66b3e40cca997aa7a4321433e7a79dabd5da93071c8ddb6cd0740a43a7661d585e5001943fea9b46a6d6b69655eba92aad29d14290c0c4292a215d19800f86e2679cc012cbb51de3c5fff76a762d9ca953fcd355bab00b966e3d868102db6ec97ce632a7907fe4a219d889ceeba133655bdee56fef750c500aad1b433d3025fe886c1710399b90e30b5a9a81b24560c0cf57ee3df52c7baa491a2cfe117f6a9ba4116ef078414e4b42aef90afb162c641d9815bdd0d2cb1cdbd9dc0bb0defd16846accd7a699d8929bc678aaa2207247e38c4984fec7d516afeb81c3b21bd1339aad36aaf80992&lang=en"
                                    style={{ border: "0", width: "100%", height: "140px", backgroundColor: "transparent" }}
                                />
                            </div>
                        </div>

                    </form>
                    <div className='create-account' >
                        Already a customer?
                        <button className='create-account-button' onClick={() => { /* Logic to switch to sign-up */ }}> Sign in</button>
                    </div>
                    <p className="text-sm text-gray-600 text-center">
                        By registering you agree to the&nbsp;
                        <br />
                        <button className="privacy-policy-button">
                            privacy policy
                        </button>
                        &nbsp;and&nbsp;
                        <button className="customer-agreement-button">
                            customer agreement
                        </button>
                        .
                    </p>
                </section>
            </div>
        </div>
    );
};

export default CreateAccount;
