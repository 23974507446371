export const FooterData = [
  {
    title: "BingsEpicerie",
    links: [
      { text: "About Us", href: "" },
      { text: "Gift Cards", href: "" },
      { text: "Blog", href: "" },
      { text: "Careers", href: "" },
      { text: "Suppliers", href: "" },
      { text: "Food Safety", href: "" },
    ],
  },
  {
    title: "Help",
    links: [
      { text: "Contact Us", href: "" },
      { text: "Delivery Information", href: "" },
      { text: "Accessibility", href: "" },
      { text: "FAQ", href: "" },
    ],
    subSection: {
      title: "Press Inquiries",
      links: [
        { text: "Email", href: "" },
        { text: "News & Media", href: "" },
      ],
    },
  },
  {
    title: "Follow Us",
    links: [
      { text: "Instagram", href: "" },
      { text: "Facebook", href: "" },
      { text: "Twitter", href: "" },
      { text: "YouTube", href: "" },
    ],
  },
  {
    title: "Get the Apps",
    links: [
      {
        text: "Apple App Store",
        href: "",
        image: "https://www.freshdirect.com/images/apple-app-store.svg",
      },
      {
        text: "Google Play Store",
        href: "",
        image: "https://www.freshdirect.com/images/google-play-store.svg",
      },
      {
        text: "Alexa",
        href: "",
        image: "https://www.freshdirect.com/images/alexa.png",
      },
    ],
  },
];
