import { Category } from "../../types/Category";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "categories";

// Récupérer toutes les catégories
export const getCategories = async (): Promise<Category[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Category[] }>(API_ENDPOINT);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des catégories:", error);
    throw error;
  }
};

// Récupérer une catégorie par son ID
export const getCategoryById = async (id: string): Promise<Category> => {
  if (!id) {
    throw new Error("ID is required");
  }
  try {
    const response = await HTTP_CLIENT.get<{ data: Category }>(`${API_ENDPOINT}/${id}`);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération de la catégorie:", error);
    throw error;
  }
};

// Fonction pour récupérer les catégories d'un MenuData spécifique
export const getCategoriesByMenuData = async (menuDataId: string): Promise<Category[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Category[] }>(`${API_ENDPOINT}/menu/${menuDataId}`);
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la récupération des catégories pour ce MenuData:", error);
    throw error;
  }
};

// Récupérer l'ID d'une catégorie par label
export const getCategoryIdByLabel = async (label: string): Promise<string> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: string; message: string; success: boolean }>(
      `${API_ENDPOINT}/name/${label}`
    );

    if (response.data.success) {
      return response.data.data; // Retourne l'ID de la catégorie (UUID)
    } else {
      throw new Error(response.data.message); // Si le succès est faux, on lance une erreur avec le message
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'ID de la catégorie par label:");
    throw error;
  }
};

// Créer une nouvelle catégorie
export const createCategory = async (category: Category): Promise<Category> => {
  try {
    const response = await HTTP_CLIENT.post<{ data: Category }>(API_ENDPOINT, {
      label: category.label,
      menuDataId: category.menuId, // Passer l'ID du MenuData ici
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error('Erreur lors de la création de la catégorie :', error);
    throw error;
  }
};

// Mettre à jour une catégorie existante
export const updateCategory = async (id: string, category: Category): Promise<Category> => {
  try {
    const response = await HTTP_CLIENT.put<{ data: Category }>(`${API_ENDPOINT}/${id}`, {
      label: category.label,
      menuDataId: category.menuId, // Mise à jour de l'ID du MenuData si nécessaire
    });
    return response.data.data; // Accéder à la propriété data de la réponse
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la catégorie:", error);
    throw error;
  }
};

// Supprimer une catégorie
export const deleteCategory = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression de la catégorie:", error);
    throw error;
  }
};
